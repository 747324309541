import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import {
  useDeleteUserMutation,
  useGetUserListQuery,
  useEditSubscriptionUserMutation,
} from "../../redux/RTK-queries/users";
import { checkLogin } from "../../redux/authSlice";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import AdminNavBar from "../../components/AdminNavBar";
import debounce from "lodash.debounce";
import moment from "moment";
import { CSVLink } from "react-csv";

import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { data: users, isFetching, isLoading } = useGetUserListQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [editSubscriptionUser] = useEditSubscriptionUserMutation();
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState(new Date());
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [occupationFilter, setOccupationFilter] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseSubscription = () => setShowSubscription(false);

  const [show, setShow] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);

  const deleteUserHandler = async () => {
    await deleteUser(userToDelete);
    setShow(false);
  };

  const editUserHandler = async () => {
    editSubscriptionUser({ user_id: userToEdit, validUntil: subscriptionDate });
    setShowSubscription(false);
  };

  const usersCsv = filteredUsers?.map((user) => ({
    nombre: user.name,
    apellido: user.lName,
    email: user.email,
    telefono: user.phone,
    ocupacion: user.occupations.map((occupation) => occupation.name).join(", "),
    direccion:
      user.addressObject?.label ?? user.addressObject?.formatted_address ?? "",
    fecha_de_registro: user.creation_date
      ? moment(user.creation_date).format("DD-MM-YYYY HH:mm:ss")
      : "",
    suscripcion: user.validUntil
      ? moment(user.validUntil).format("DD-MM-YYYY HH:mm:ss")
      : "",
  }));

  const handleSearch = debounce((event) => {
    const { value } = event.target;
    setSearchTerm(value);
  }, 500);

  useEffect(() => {
    if (users) {
      let currentUsers = users;

      if (occupationFilter) {
        const filteredUsersWithOccupation = currentUsers.filter((user) => {
          return user.occupations.length === 0;
        });
        currentUsers = filteredUsersWithOccupation;
      }

      if (searchTerm) {
        let filteredUsers = currentUsers;

        if (occupationFilter) {
          const filteredUsersWithOccupation = filteredUsers.filter((user) => {
            return user.occupations.length === 0;
          });
          filteredUsers = filteredUsersWithOccupation;
        }

        filteredUsers = filteredUsers.filter((user) => {
          const address =
            user.addressObject?.formatted_address ??
            user.addressObject?.label ??
            "";

          return (
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.occupations.some((occupation) =>
              occupation.name.toLowerCase().includes(searchTerm.toLowerCase()),
            )
          );
        });

        currentUsers = filteredUsers;
      }

      setFilteredUsers(currentUsers);
    }
  }, [searchTerm, occupationFilter, users]);

  useEffect(() => {
    dispatch(checkLogin());
  }, []);

  return (
    <div className={"container-fluid"}>
      <AdminNavBar />
      <div className={"container-fluid px-5"}>
        <h1>Dashboard de usuarios</h1>

        {(isFetching || isLoading) && <p>Loading...</p>}
        {!isFetching && !isLoading && users && (
          <div>
            <div className="d-flex gap-2 align-items-center mb-1">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar..."
                onChange={handleSearch}
                autoFocus
              />
              <div className="form-check me-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => setOccupationFilter(e.target.checked)}
                />
                <label
                  className="form-check-label"
                  for="flexCheckDefault"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Sin ocupación
                </label>
              </div>
              <CSVLink
                data={usersCsv}
                filename={`usuarios-${moment().format("DD-MM-YYYY")}.csv`}
                className="btn btn-primary px-3"
                style={{ whiteSpace: "nowrap" }}
                target="_blank"
              >
                Descargar CSV
              </CSVLink>
            </div>
            <div
              style={{
                maxHeight: 600,
                overflow: "auto",
                borderBottom: "1px solid lightgray",
              }}
            >
              <table className={"table table-striped"}>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "lightgray",
                  }}
                >
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Email</th>
                  <th>Telefono</th>
                  <th>Ocupación</th>
                  <th>Dirección</th>
                  <th>Fecha de registro</th>
                  <th>Suscripción</th>
                  <th>Suscripción Expira</th>
                  <th>Acciones</th>
                </thead>
                <tbody>
                  {filteredUsers?.map((user) => (
                    <tr key={user._id}>
                      <td>{user.name}</td>
                      <td>{user.lName}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        {user.occupations
                          .map((occupation) => occupation.name)
                          .join(", ")}
                      </td>
                      <td>
                        {user.addressObject?.label?.toString() ??
                          user.addressObject?.formatted_address?.toString() ??
                          ""}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {user.creation_date
                          ? moment(user.creation_date).format(
                              "DD-MM-YYYY HH:mm:ss",
                            )
                          : ""}
                      </td>
                      <td>{user.subscription_id ?? "No posee"}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {user.validUntil
                          ? moment(user.validUntil).format(
                              "DD-MM-YYYY HH:mm:ss",
                            )
                          : ""}
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <button
                            className={"btn btn-success px-2"}
                            onClick={() => {
                              setShowSubscription(true);
                              setUserToEdit(user._id);
                            }}
                          >
                            Suscripción
                          </button>
                          <Link
                            to={"/second?behalf_user_id=" + user._id}
                            className={"btn btn-dark px-2"}
                          >
                            {"Editar"}
                          </Link>
                          <button
                            className={"btn btn-danger px-2"}
                            onClick={() => {
                              setShow(true);
                              setUserToDelete(user._id);
                            }}
                          >
                            Borrar
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: 5 }}>
              <span>Usuarios totales: {filteredUsers?.length}</span>
            </div>
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose} style={{ zIndex: 100000000000 }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Está seguro que desea borrar el elemento?</p>
        </Modal.Body>

        <Modal.Footer>
          <div className={"row"}>
            <div className={"col-6"}>
              <Button
                variant="secondary"
                onClick={() => {
                  setUserToDelete(null);
                  setShow(false);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className={"col-6"}>
              <Button variant="primary" onClick={deleteUserHandler}>
                Borrar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSubscription}
        onHide={handleCloseSubscription}
        style={{ zIndex: 100000000000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Extender Suscripción</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Hasta que fecha quieres agregar suscripción</p>
          <DatePicker
            selected={subscriptionDate}
            onChange={(date) => setSubscriptionDate(date)}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className={"row"}>
            <div className={"col-6"}>
              <Button
                variant="secondary"
                onClick={() => {
                  setUserToEdit(null);
                  setShowSubscription(false);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className={"col-6"}>
              <Button variant="primary" onClick={editUserHandler}>
                Actualizar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
