import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkLogin} from "../../redux/authSlice";

const ValidateUser = ({children}) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {token, user} = useSelector(state => state.auth);

    useEffect(()=>{
        const localToken = localStorage.getItem('user.token');
        if(!localToken){
            navigate('/');
        }else{
            if(!user?._id){
                dispatch(checkLogin());
            }
        }
    },[token, user])

    if(token){
        return (<>
            {children}
        </>);
    }

}

export default ValidateUser;