import React from 'react';
import {Link, useLocation} from "react-router-dom";
import logo from '../assets/images/logo_quip.png'
import {logout} from "../redux/authSlice";
import {useDispatch} from "react-redux";

const AdminNavBar = () =>{
    const location  = useLocation()
    const dispatch = useDispatch()
    return(
        <nav className="navbar navbar-expand-lg navbar-light py-2 bg-white w-100" id='mainNav'>
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="Software developer" width="30" height="30" className="logo"/>
                </a>
                <div className="d-flex flex-row-reverse">
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <div className="d-flex justify-content-end">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link
                                        className={location.pathname === '/admin' ? "nav-link active" : "nav-link" }
                                        to="/admin"
                                    >
                                       Usuarios
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={location.pathname === '/occupations' ? "nav-link active" : "nav-link" }
                                        to="/occupations"
                                    >
                                        Ocupaciones
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <div
                                        className={"nav-link" }
                                        onClick={()=>dispatch(logout())}
                                    >
                                        Salir
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </nav>
    )
}

export default AdminNavBar;

