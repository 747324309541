import React, {useEffect, useRef, useState} from 'react';
import '../assets/css/home.css'
import logo from '../assets/images/logo_quip.png';

import {FaUserAlt} from "react-icons/fa";
import {AiFillPhone, AiOutlineLock, AiOutlineMail} from "react-icons/ai";
import {useForm} from "react-hook-form";
import  phoneValidator from 'phone';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {checkLogin, createUser} from "../redux/authSlice";
import NavBar from "../components/NavBar";


const Home = () =>{
    const { register, handleSubmit } = useForm();

    const { token, user, error } = useSelector(state => state.auth)

    const [localError, setLocalError] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(checkLogin());
    }, [])

    useEffect(()=>{
        if(token != ''){
            navigate('/second?ve=10');
        }
    },[token])



    const onSubmit = data => {
        setLocalError('');
        const {email, password, confirmPassword, name, lName, phone, confirmation} = data;
        if(email, password, confirmPassword, name, lName, phone, confirmation){
           let valid = true;
            if(password.length < 8 || password != confirmPassword){
                setLocalError('La contraseña debe ser de al menos 8 digitos y deben coincidir')
                valid=false;
            }
            const regex = /^\w+([\.-]?\w+[\.-]?)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!regex.test(email)){
                setLocalError('El mail debe de ser válido');
                valid=false;
            }

            if(!phoneValidator('+52'+phone).isValid){
                setLocalError('El telefono es invalido, por favor ingreselo a 10 digitos');
                valid=false;
            }

            if(valid){
                dispatch(createUser(data));
            }

        }else{
            setLocalError("Por favor llene todos los campos y acepte los términos y condiciones");
        }
    }


    return(
        <>
            <div className="container-fluid my-0 px-0">
                <div className={'d-block d-md-none'}>
                    <NavBar  />
                </div>
                <div className={'row '}>
                    <div className={'col-md-6 h-100 background-image ps-lg-5 d-none  d-md-block '} style={{minHeight: 1000}}>
                        <div className={'text-center'}>
                            <a href={'https://quip.com.mx'}><img src={logo} alt="logo" className={'mt-md-5 '}/></a>
                        </div>
                        <div className={'mt-md-5 text-center text-white'}>
                            <h1 className={'text-white'}>Únete a Quip Solutions</h1>

                        </div>
                        <div className={'text-white pt-5'}>
                            <h4 className={'mt-5'}>Datos Mínimos</h4>
                            <ul>
                                <li>Nombre</li>
                                <li>Email</li>
                                <li>Ocupación</li>
                                <li>Servicios prestados</li>
                                <li>Teléfono de contacto fijo, celular y/o Whatsapp</li>
                                <li>Dirección</li>
                            </ul>
                            <h4 className={'mt-5'}>Si quieres certificar tu perfil te recomendamos tener</h4>
                            <ul>
                                <li>INE o pasaporte</li>
                                <li>Registo Federal de Contribuyentes (RFC)</li>
                                <li>Razón Social</li>
                                <li>Comprobante de domicilio</li>
                            </ul>
                        </div>

                        <a href={'https://quip.com.mx'}>Ver más...</a>

                    </div>
                    <div className={'col-md-6 fondo-corporativo pt-3 px-5 pb-5'}>
                        <h3 className={'text-center'}>Crea tu cuenta con Quip Solutions</h3>
                        <form onSubmit={handleSubmit(onSubmit)} className={'px-lg-5 mt-5'}>
                            <div className="form-group my-3">
                                <label htmlFor="name">Nombre(s)</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><FaUserAlt size={21} /></div>
                                    </div>
                                    <input type="text" {...register("name" )} className="form-control"
                                           placeholder="Nombre"/>
                                </div>
                            </div>
                            <div className="form-group my-3">
                                    <label htmlFor="name">Apellido</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><FaUserAlt size={21} /></div>
                                        </div>
                                        <input type="text"  {...register("lName")} className="form-control" id="inlineFormInputGroup"
                                               placeholder="Apellido"/>
                                    </div>
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiOutlineMail size={21} /></div>
                                    </div>
                                    <input type="email"  {...register("email")} className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="exampleInputEmail1">Teléfono</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiFillPhone size={21} /></div>
                                    </div>
                                    <input type="phone"  {...register("phone")} className="form-control" id="exampleInputPhone"
                                           aria-describedby="phoneHelp" placeholder="Teléfono"/>

                                </div>
                                <small>Introduzca su número a 10 dígitos</small>
                            </div>
                            <div className="form-group my-3">

                                <label htmlFor="exampleInputPassword1">Contraseña</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiOutlineLock size={21} /></div>
                                    </div>
                                    <input type="password" className="form-control"  {...register("password")}
                                           placeholder="Contraseña" />
                                </div>
                                <small>Mínimo 8 dígitos, se recomienda el uso de Mayúsculas, minúsculas y números</small>

                            </div>
                            <div className="form-group my-3">

                                <label htmlFor="exampleInputPassword1">Contraseña</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiOutlineLock size={21} /></div>
                                    </div>
                                    <input type="password" className="form-control"  {...register("confirmPassword")}
                                           placeholder="Valida contraseña" />
                                </div>

                            </div>
                            <div className="form-check">
                                <input type="checkbox" {...register("confirmation")} className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Al registrarse, acepta los <a href="https://quip-container.sfo3.digitaloceanspaces.com/contracts/terminosYCondiciones.pdf">Términos y Condiciones</a> de Quip Solutions y reconoce que ha leido las <a href="https://quip-container.sfo3.digitaloceanspaces.com/contracts/privacidad.pdf">Políticas de Privacidad</a></label>
                            </div>
                            {localError && <div className="alert alert-danger">{localError}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="text-center mt-4">
                                <input type="submit" className="btn btn-primary " value={'Crear cuenta'} />
                            </div>
                            <div className="text-center mt-4">
                                <p onClick={()=>{navigate('/login')}} className={'btn btn-secondary'}> Inicia Sesión</p>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Home