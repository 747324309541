import * as React from "react";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Home from "./views/Home";
import SecondStep from "./views/SecondStep.js";
import ThirdStep from "./views/ThirdStep.js";
import Success from "./views/Success.js";
import Login from "./views/Login.js";
import {LoadScript} from "@react-google-maps/api";
import Dashboard from "./views/admin/Dashboard";
import ValidateUser from "./components/security/ValidateUser";
import Occupations from "./views/admin/Occupations";
import Blog from "./views/Blog";
import Oxxo from "./views/Oxxo";
import PasswordReset from "./views/PasswordReset";


function App() {

    const stripePromise = loadStripe('pk_live_51LAeJhADVSo2mED9NHGVPxRhi2lFWyuciDUGi36R3qAxEgs5IehkSXNIVOpgJm4mjJl81WHE6l2szxTjhYZ8C7al00ELic3hUO');
  return (
      <LoadScript
          googleMapsApiKey="AIzaSyAKv5tOPUQM-NOSoui1NbrxDGGjnJg_Jz8"
          libraries={["places"]}
      >
          <Elements stripe={stripePromise} >
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/blog" element={<Blog />} />
                      <Route path="/second" element={<SecondStep />} />
                      <Route path="/third" element={<ThirdStep />} />
                      <Route path="/success" element={<Success />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/oxxo" element={<Oxxo />} />
                      <Route path="/recover/:token" element={<PasswordReset />} />
                      <Route path="/admin" element={<ValidateUser>
                          <Dashboard />
                      </ValidateUser>} />
                      <Route path="/occupations" element={<ValidateUser>
                          <Occupations />
                      </ValidateUser>} />
                  </Routes>
              </BrowserRouter>
          </Elements>
      </LoadScript>
  );
}

export default App;



