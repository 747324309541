import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import { useFieldArray, useForm } from "react-hook-form";
import { checkLogin, updateUser } from "../redux/authSlice";
import { FaUserAlt } from "react-icons/fa";
import ReactSlider from "react-slider";
import AttentionSchedule from "./services/AttentionSchedule";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadImage from "../components/UploadImage";
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
//import Autocomplete from "react-google-autocomplete";

const ThirdStep = () => {
  const location = useLocation();
  const [myAutocomplete, setMyAutocomplete] = useState();

  const { register, handleSubmit, control, setValue } = useForm();
  const [distance, setDistance] = useState(10);

  const [lat, setLat] = useState(20.676355);
  const [lng, setLng] = useState(-103.346982);
  const [markerLat, setMarkerLat] = useState(20.676355);
  const [markerLng, setMarkerLng] = useState(-103.346982);
  const [zoom, setZoom] = useState(13);
  const [myMarker, setMyMarker] = useState(false);

  const [myMap, setMyMap] = useState();

  const [monStartTime, setMonStartTime] = useState("08:00");
  const [monEndTime, setMonEndTime] = useState("18:00");
  const [monActive, setMonActive] = useState(true);

  const [tueStartTime, setTueStartTime] = useState("08:00");
  const [tueEndTime, setTueEndTime] = useState("18:00");
  const [tueActive, setTueActive] = useState(true);

  const [wedStartTime, setWedStartTime] = useState("08:00");
  const [wedEndTime, setWedEndTime] = useState("18:00");
  const [wedActive, setWedActive] = useState(true);

  const [thuStartTime, setThuStartTime] = useState("08:00");
  const [thuEndTime, setThuEndTime] = useState("18:00");
  const [thuActive, setThuActive] = useState(true);

  const [friStartTime, setFriStartTime] = useState("08:00");
  const [friEndTime, setFriEndTime] = useState("18:00");
  const [friActive, setFriActive] = useState(true);

  const [satStartTime, setSatStartTime] = useState("08:00");
  const [satEndTime, setSatEndTime] = useState("13:00");
  const [satActive, setSatActive] = useState(false);

  const [sunStartTime, setSunStartTime] = useState("08:00");
  const [sunEndTime, setSunEndTime] = useState("12:00");
  const [sunActive, setSunActive] = useState(false);

  const [address, setAddress] = useState("");
  const [addressInput, setAddressInput] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { token, user, error } = useSelector((state) => state.auth);

  const [identification, setIdentification] = useState("");
  const [proofOfAddress, setProofOfAddress] = useState("");

  const behalf_user_id = new URLSearchParams(location.search).get(
    "behalf_user_id",
  );
  console.log("FROM ThirdStep.js behalf_user_id", behalf_user_id);

  useEffect(() => {
    dispatch(checkLogin());
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    if (address) {
      setMyMarker(true);
    }
  }, [address]);

  useEffect(() => {
    if (user.addressObject) {
      setAddress(user.addressObject);
      setAddressInput(
        user.addressObject?.label ??
          user.addressObject?.formatted_address ??
          "",
      );
      setZoom(17);
    }

    if (user.lat) {
      setLat(user.lat);
      setMarkerLat(user.lat);
    }
    if (user.lng) {
      setLng(user.lng);
      setMarkerLng(user.lng);
    }

    if (user.identification) {
      setIdentification(user.identification);
    }

    if (user.proofOfAddress) setProofOfAddress(user.proofOfAddress);

    if (user.razons) {
      console.log("ENTRA A USER RAZONS", user.razons);
      setValue("razons", user.razons);
    }

    if (user.weeklySchedule) {
      setMonActive(user.weeklySchedule.mon.active);
      setMonStartTime(user.weeklySchedule.mon.start);
      setMonEndTime(user.weeklySchedule.mon.end);

      setTueActive(user.weeklySchedule.tue.active);
      setTueStartTime(user.weeklySchedule.tue.start);
      setTueEndTime(user.weeklySchedule.tue.end);

      setWedActive(user.weeklySchedule.wed.active);
      setWedStartTime(user.weeklySchedule.wed.start);
      setWedEndTime(user.weeklySchedule.wed.end);

      setThuActive(user.weeklySchedule.thu.active);
      setThuStartTime(user.weeklySchedule.thu.start);
      setThuEndTime(user.weeklySchedule.thu.end);

      setFriActive(user.weeklySchedule.fri.active);
      setFriStartTime(user.weeklySchedule.fri.start);
      setFriEndTime(user.weeklySchedule.fri.end);

      setSatActive(user.weeklySchedule.sat.active);
      setSatStartTime(user.weeklySchedule.sat.start);
      setSatEndTime(user.weeklySchedule.sat.end);

      setSunActive(user.weeklySchedule.sun.active);
      setSunStartTime(user.weeklySchedule.sun.start);
      setSunEndTime(user.weeklySchedule.sun.end);
    }
  }, [user]);

  useEffect(() => {
    if (address) {
      console.log("La nueva address " + "s:: ", address);

      /*geocodeByPlaceId(address.value.place_id)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    console.log('Successfully got latitude and longitude', { lat, lng })
                )*/
    }
  }, [address]);

  const onSubmit = async (data) => {
    console.log(data.rfc.length);
    if (data.rfc != "" && (data.rfc.length > 13 || data.rfc.length < 12)) {
      return alert("El RFC solo puede ser de 12 o 13 dígitos");
    }
    data.identification = identification;
    data.proofOfAddress = proofOfAddress;
    data.distance = distance;
    data.addressObject = address;
    data.lat = markerLat;
    data.lng = markerLng;
    data.weeklySchedule = {
      mon: {
        active: monActive,
        start: monStartTime,
        end: monEndTime,
      },
      tue: {
        active: tueActive,
        start: tueStartTime,
        end: tueEndTime,
      },
      wed: {
        active: wedActive,
        start: wedStartTime,
        end: wedEndTime,
      },
      thu: {
        active: thuActive,
        start: thuStartTime,
        end: thuEndTime,
      },
      fri: {
        active: friActive,
        start: friStartTime,
        end: friEndTime,
      },
      sat: {
        active: satActive,
        start: satStartTime,
        end: satEndTime,
      },
      sun: {
        active: sunActive,
        start: sunStartTime,
        end: sunEndTime,
      },
    };
    data.behalf_user_id = behalf_user_id;

    dispatch(updateUser(data));
    navigate("/success");
  };
  /*const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <div>Esta cargando</div>;
            case Status.FAILURE:
                return <div>ERROR!!!!!!</div>;
            case Status.SUCCESS:
                return <MyMapComponent />;
        }
    };*/

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  return (
    <>
      <div className="container-fluid px-0 ">
        <NavBar back={"/second"} />
        <div className={"row"}>
          <div className={"col-md-6 offset-md-3  pt-3 px-5"}>
            <div className={"container-fluid"}>
              <div className={"row"}>
                <div className={"col-4 px-2"}>
                  <div className={"step-success"}></div>
                </div>
                <div className={"col-4 px-2"}>
                  <div className={"step-success"}></div>
                </div>
                <div className={"col-4 px-2"}>
                  <div className={"step-success"}></div>
                </div>
              </div>
            </div>
            <h2 className={"text-center"}>Información laboral</h2>
            <form onSubmit={handleSubmit(onSubmit)} className={"mt-5"}>
              <div className="form-group my-3">
                <label htmlFor="name">
                  Registro Federal de Contribuyentes (opcional)
                </label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FaUserAlt size={21} />
                    </div>
                  </div>
                  <input
                    type="text"
                    {...register("rfc")}
                    className="form-control"
                    placeholder="Nombre"
                  />
                </div>
              </div>

              <label className={"mb-3"}>
                Escribe (en el recuadro) y selecciona tu dirección
              </label>

              {!address && (
                <Autocomplete
                  restrictions={{ country: "mx" }}
                  onLoad={(autocomplete) => {
                    console.log("Autocomplete loaded");
                    setMyAutocomplete(autocomplete);
                  }}
                  onPlaceChanged={(place) => {
                    setLat(myAutocomplete.getPlace().geometry.location.lat());
                    setLng(myAutocomplete.getPlace().geometry.location.lng());
                    setMarkerLat(
                      myAutocomplete.getPlace().geometry.location.lat(),
                    );
                    setMarkerLng(
                      myAutocomplete.getPlace().geometry.location.lng(),
                    );
                    setZoom(17);
                    setAddress(myAutocomplete.getPlace());
                    setAddressInput(
                      myAutocomplete.getPlace().formatted_address,
                    );
                    console.log("Place changed", myAutocomplete.getPlace());
                  }}
                >
                  <input
                    type="text"
                    placeholder="Introduce tu dirección"
                    value={addressInput}
                    onChange={(e) => {
                      setAddressInput(e.target.value);
                    }}
                    className="form-control"
                  />
                </Autocomplete>
              )}
              {address && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{ lat, lng }}
                  zoom={zoom}
                  onDragEnd={(center) => {
                    console.log("center", center);
                    if (myMap) {
                      console.log(
                        "myMap",
                        myMap.center.lat(),
                        myMap.center.lng(),
                      );
                      // setMarkerLat(myMap.center.lat()); setMarkerLng(myMap.center.lng())
                      setLat(myMap.center.lat());
                      setLng(myMap.center.lng());
                    }
                  }}
                  onLoad={(map) => {
                    setMyMap(map);
                  }}
                >
                  <Autocomplete
                    restrictions={{ country: "mx" }}
                    onLoad={(autocomplete) => {
                      console.log("Autocomplete loaded");
                      setMyAutocomplete(autocomplete);
                    }}
                    onPlaceChanged={(place) => {
                      setLat(myAutocomplete.getPlace().geometry.location.lat());
                      setLng(myAutocomplete.getPlace().geometry.location.lng());
                      setMarkerLat(
                        myAutocomplete.getPlace().geometry.location.lat(),
                      );
                      setMarkerLng(
                        myAutocomplete.getPlace().geometry.location.lng(),
                      );
                      setZoom(17);
                      setAddress(myAutocomplete.getPlace());
                      setAddressInput(
                        myAutocomplete.getPlace().formatted_address,
                      );
                      console.log("Place changed", myAutocomplete.getPlace());
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Introduce tu dirección"
                      value={addressInput}
                      onChange={(e) => {
                        setAddressInput(e.target.value);
                      }}
                      style={{
                        boxSizing: `border-box`,
                        border: `3px solid transparent`,
                        width: `240px`,
                        height: `45px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(226, 67, 2, 1)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-120px",
                        zIndex: 100000000,
                      }}
                    />
                  </Autocomplete>
                  {myMarker && address ? (
                    <Marker
                      title={"Mi ubicación"}
                      onDragEnd={(e) => {
                        setMarkerLat(e.latLng.lat());
                        setMarkerLng(e.latLng.lng());
                        console.log(e.latLng);
                      }}
                      draggable={true}
                      position={{ lat: markerLat, lng: markerLng }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "red",
                          padding: 5,
                        }}
                      >
                        <span style={{ color: "white" }}>
                          Usuario no tiene coordenadas
                        </span>
                      </div>
                    </div>
                  )}
                </GoogleMap>
              )}

              <div className="form-group my-3">
                <label htmlFor="name">Rango de atención (Km)</label>
                <br />
                <small>
                  El rango de atención es el área de cobertura que tienen tus
                  servicios, es decir, hasta donde atiendes.
                </small>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  max={15}
                  value={distance}
                  onChange={(value) => {
                    setDistance(value);
                  }}
                  renderThumb={(props, state) => (
                    <div {...props}>{state.valueNow}</div>
                  )}
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="name">Horario de atención</label>
                <AttentionSchedule
                  day={"Lun"}
                  setStartTime={setMonStartTime}
                  setEndTime={setMonEndTime}
                  active={monActive}
                  setActive={setMonActive}
                  startTime={monStartTime}
                  endTime={monEndTime}
                />
                <AttentionSchedule
                  day={"Mar"}
                  setStartTime={setTueStartTime}
                  setEndTime={setTueEndTime}
                  active={tueActive}
                  setActive={setTueActive}
                  startTime={tueStartTime}
                  endTime={tueEndTime}
                />
                <AttentionSchedule
                  day={"Mie"}
                  setStartTime={setWedStartTime}
                  setEndTime={setWedEndTime}
                  active={wedActive}
                  setActive={setWedActive}
                  startTime={wedStartTime}
                  endTime={wedEndTime}
                />
                <AttentionSchedule
                  day={"Jue"}
                  setStartTime={setThuStartTime}
                  setEndTime={setThuEndTime}
                  active={thuActive}
                  setActive={setThuActive}
                  startTime={thuStartTime}
                  endTime={thuEndTime}
                />
                <AttentionSchedule
                  day={"Vie"}
                  setStartTime={setFriStartTime}
                  setEndTime={setFriEndTime}
                  active={friActive}
                  setActive={setFriActive}
                  startTime={friStartTime}
                  endTime={friEndTime}
                />
                <AttentionSchedule
                  day={"Sab"}
                  setStartTime={setSatStartTime}
                  setEndTime={setSatEndTime}
                  active={satActive}
                  setActive={setSatActive}
                  startTime={satStartTime}
                  endTime={satEndTime}
                />
                <AttentionSchedule
                  day={"Dom"}
                  setStartTime={setSunStartTime}
                  setEndTime={setSunEndTime}
                  active={sunActive}
                  setActive={setSunActive}
                  startTime={sunStartTime}
                  endTime={sunEndTime}
                />
              </div>

              <UploadImage
                name={"Indentificación"}
                setParentImage={setIdentification}
                parentImage={identification}
                setParentLoading={() => {}}
                setParentStopLoading={() => {}}
              />
              <UploadImage
                name={"Comprobante de domicilio"}
                setParentImage={setProofOfAddress}
                parentImage={proofOfAddress}
                setParentLoading={() => {}}
                setParentStopLoading={() => {}}
              />

              <div className="mt-5" style={{ textAlign: "right" }}>
                <input
                  type="submit"
                  disabled={!address}
                  className="btn btn-primary "
                  value={"Finalizar"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
