import { useState, useEffect } from "react";

import { Card, Form, Button, Alert, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import logo from "../assets/images/logo_quip.png";

export default function PasswordReset() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [validatingToken, setValidatingToken] = useState(true);
  const [email, setEmail] = useState(null);

  const tokenIsNotValid = validatingToken === false && email === null;

  const { token } = useParams();

  async function handleSubmit(event) {
    event.preventDefault();

    if (!success) {
      setError(null);
      setSuccess(null);

      const data = new FormData(event.target);

      const password = data.get("password");
      const password_confirmation = data.get("password-confirmation");

      if (password !== password_confirmation) {
        setError("Las contraseñas no coinciden");
        return;
      }

      const response = await fetch("/api/user/recovery-change-pass", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ password }),
      });

      if (response.status === 200) {
        setSuccess("Contraseña cambiada exitosamente");
      } else {
        const data = await response.json();
        setError(data.error);
      }
    }
  }

  useEffect(() => {
    if (token) {
      fetch("/api/user/validate-recovery", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            setEmail(data.email);
          }
        })
        .finally(() => {
          setValidatingToken(false);
        });
    }
  }, [token]);

  if (validatingToken) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div
          className="position-absolute"
          style={{ left: "2rem", top: "2rem" }}
        >
          <img src={logo} alt="logo" className="logo" width={75} />
        </div>
        <Spinner animation="border" />
      </div>
    );
  }

  if (tokenIsNotValid) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div
          className="position-absolute"
          style={{ left: "2rem", top: "2rem" }}
        >
          <img src={logo} alt="logo" className="logo" width={75} />
        </div>
        <Alert variant="danger">
          El link no es válido o expiró. Por favor intente de nuevo.
        </Alert>
      </div>
    );
  }

  return (
    <div className="w-100 h-100 pt-4">
      <div className="d-flex justify-content-center">
        <img src={logo} alt="logo" className="logo" width={75} />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100% - 5rem)" }}
      >
        <Card className="p-5" style={{ minWidth: 450 }}>
          <Card.Body>
            <Form
              onSubmit={handleSubmit}
              className="d-flex flex-column justify-content-center"
            >
              <Form.Group className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  name="password"
                  className="border-1 border-dark"
                  type="password"
                  placeholder="Ingrese la nueva contraseña"
                  disabled={success}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirmar contraseña</Form.Label>
                <Form.Control
                  name="password-confirmation"
                  className="border-1 border-dark"
                  type="password"
                  placeholder="Confirme la nueva contraseña"
                  disabled={success}
                />
              </Form.Group>

              {error ? (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              ) : null}

              {success ? (
                <Alert variant="success" className="mt-3">
                  {success}
                </Alert>
              ) : null}

              <Button variant="primary" type="submit" disabled={success}>
                Enviar
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
