import React, {useEffect} from 'react'
import {useStripe} from "@stripe/react-stripe-js";
import {useSearchParams} from "react-router-dom";

const Oxxo = () => {
    const stripe = useStripe();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        console.log('the search params are', searchParams.get("intent"));
        const intent_secret = searchParams.get("intent")
        const name = searchParams.get("name")
        const email = searchParams.get("email")
        const fetchData =  async() => {
            await stripe.confirmOxxoPayment(intent_secret, {
                payment_method: {
                    billing_details: {
                        name,
                        email,
                    },
                },
            })
        }

        fetchData().then(()=>{}).catch((e)=>{console.log(e)})

    }, [stripe, searchParams])
    return(
        <div>

        </div>
    )
}

export default Oxxo