import React from 'react';
import TimePicker from "react-time-picker";


const AttentionSchedule = ({day, setStartTime, setEndTime, setActive, active, startTime, endTime}) =>{
    return(
        <div className={'row my-3'}>
            <div className={'col-2 pt-2'}>
                {day}
            </div>
            <div className={'col-2 pt-2'}>
                <input type="checkbox"
                       checked={active}
                       onChange={(e)=>{
                           setActive(e.target.checked)
                       }}
                       className="form-check-input"
                       id="exampleCheck1"
                />
            </div>
            <div className={'col-4 bg-white'}>
                {active && <TimePicker onChange={setStartTime} value={startTime} className={'form-control'} />}
                {!active && <input
                    type={'text'}
                    value={startTime}
                    disabled={true}// {String}   required, format '00:00' or '00:00:00'
                    onChange={(event, value) => {}} // {Function} required
                    style={{
                        border: '0px solid #666',
                        fontSize: 18,
                        width: '100%',
                        padding: '5px 8px',
                        color: '#333',
                        borderRadius: 3,
                        background: active?'white':'#bbbbbb'
                    }}
                />}
            </div>
            <div className={'col-4 bg-white'}>
                {active && <TimePicker
                    onChange={setEndTime}
                    value={endTime}
                    className={'form-control'}
                />}
                {!active && <input
                    type={'text'}
                    value={endTime}
                    disabled={true}// {String}   required, format '00:00' or '00:00:00'
                    onChange={(event, value) => {}} // {Function} required
                    style={{
                        border: '0px solid #666',
                        fontSize: 18,
                        width: '100%',
                        padding: '5px 8px',
                        color: '#333',
                        borderRadius: 3,
                        background: active?'white':'#bbbbbb'
                    }}
                />}
            </div>
        </div>
    )
}

export default AttentionSchedule