import React, {useEffect, useRef, useState} from 'react';
import {useForm, Controller} from "react-hook-form";
import {useGetOccupationListQuery} from "../redux/RTK-queries/occupation";
import {BiBriefcaseAlt} from 'react-icons/bi';
import Select from 'react-select'
import Service from "./services/Service";
import NavBar from "../components/NavBar";
import {useDispatch, useSelector} from "react-redux";
import {checkLogin, updateUser} from "../redux/authSlice";
import {useNavigate, useLocation} from "react-router-dom";
import {useGetServicesListQuery} from "../redux/RTK-queries/services";
import UploadImage from "../components/UploadImage";



const SecondStep = () =>{

    const location = useLocation()
    const timestampRef = useRef(Date.now()).current;

    const ve = new URLSearchParams(location.search).get('ve');
    const behalf_user_id = new URLSearchParams(location.search).get('behalf_user_id');

    const { register, handleSubmit, control } = useForm();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState([]);
    const [upload, setUpload] = useState(false);

    const [selectOccupation, setSelectOccupation] = useState(true);

    const { token, user, error } = useSelector(state => state.auth)

    const [localServices, setLocalServices] = useState([{}]);

    const [profilePic, setProfilePic] = useState('');

    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const refSubmitButton = useRef(null);

    const [errorMessage, setErrorMessage] =    useState('');

    const { data: occupations, isFetching, isLoading, isError } = useGetOccupationListQuery();
    const { data: services, isFetching: servicesFetching, isLoading: servicesLoading, isError: servicesError } = useGetServicesListQuery({sessionId: timestampRef});

    const [newLoading, setNewLoading] = useState(-1);
    const [removeLoading, setRemoveLoading] = useState(-1);

    useEffect(()=>{
        if(newLoading != -1){
            let newArray = [...loading].filter((i)=>i != newLoading);
            newArray.push(newLoading);
            setLoading(newArray);
        }
    },[newLoading]);

    useEffect(()=>{
        if(!token || !!!user?._id ){
            navigate('/');
        }
    },[token, user])

    useEffect(()=>{
        setProfilePic(user.profilePic);
        setDescription(user.description);
    },[user])

    useEffect(()=>{
        if(removeLoading){
            let newArray = [...loading].filter((i)=>i != removeLoading);
            setLoading(newArray);
        }
    },[removeLoading]);

    useEffect(()=>{
        if(services && services.length > 0){
            setLocalServices(services);
        }
    },[services]);

    useEffect(()=>{
        if(user.occupations && user.occupations.length > 0){
                console.log(user.occupations);
                setSelectOccupation(false);
        }
    },[user])

    useEffect(()=>{
        console.log('entra a hook de validación')
        if(behalf_user_id && user?._id != behalf_user_id){
            console.log('entra a cambiar el behalf');
            dispatch(checkLogin(behalf_user_id));
        }
    },[behalf_user_id])




    const onSubmit = (data) =>{
        console.log(data);
        setErrorMessage('')
        if(!selectOccupation || data.occupation){
            if(!selectOccupation || (data.occupation.length > 0 &&  data.occupation.length <= 2)){
                // if(!profilePic){
                //     setErrorMessage('Debes subir una foto de perfil')
                //     return
                // }
                // if(!description){
                //     setErrorMessage('Debes escribir una descripción')
                //     return
                // }
                
                if(selectOccupation){
                    data.profilePic = profilePic;
                    data.description = description;
                    dispatch(updateUser({...data, behalf_user_id}));
                }else{
                    data.profilePic = profilePic;
                    data.description = description;
                    dispatch(updateUser({...data, behalf_user_id}));
                }
                setUpload(true);
                setTimeout(()=> {
                    navigate('/third' + (behalf_user_id ? '?behalf_user_id='+ behalf_user_id : ''));
                },300);
            }else{
                setErrorMessage('Solo se permiten 2 ocupaciones y mínimo 1');

            }


        }else{
            setErrorMessage('Selecciona una ocupación');
        }

    }


    if(servicesFetching){
        return <div>Loading...</div>
    }
    if(token){
        return(
            <>
                <div className="container-fluid px-0 my-0 vh-100 fondo-corporativo">
                    <NavBar />
                    <div className={'row'}>
                        <div className={'col-md-6 offset-md-3  pt-3 px-5'}>
                            <div className={'container-fluid'}>
                                <div className={'row'}>
                                    <div className={'col-4 px-2'}>
                                        <div className={'step-success'}>
                                        </div>
                                    </div>
                                    <div className={'col-4 px-2'}>
                                        <div className={'step-success'}>
                                        </div>
                                    </div>
                                    <div className={'col-4 px-2'}>
                                        <div className={'step-missing'}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className={'text-center'}>Información laboral</h2>
                            <form onSubmit={handleSubmit(onSubmit)} className={'mt-5'}>
                                <div className="form-group my-3 bg-important rounded p-2">
                                    <label htmlFor="name">Ocupación**</label><br/>
                                    <small>Debes seleccionar mínimo una ocupación, máximo 2, estas servirán para que tus clientes te puedan encontrar de acuerdo a lo que te dedicas</small>
                                    {!selectOccupation && <div><p>
                                        {user.occupations.map(el=>{
                                            return <span key={el._id}>{el.name}; </span>
                                        })}
                                    </p>
                                        <a onClick={()=>{setSelectOccupation(true)}} href={'#'}>Cambiar ocupaciones</a>
                                    </div>}
                                    {selectOccupation && <div className="input-group mb-2">
                                        <div className="input-group-prepend ">
                                            <div className="input-group-text pb-3"><BiBriefcaseAlt size={30} /></div>
                                        </div>
                                        <Controller
                                            control={control}
                                            defaultValue={[]}
                                            name="occupation"
                                            render={({field}) => {
                                                let options=[];
                                                occupations?.map(el=>{
                                                    const newElement = {value: el._id, label: el.name}
                                                    options.push(newElement)
                                                })
                                                return(
                                                    <Select
                                                        isMulti={true}
                                                        className="basic-multi-select form-control"
                                                        classNamePrefix="select"
                                                        options={options}
                                                        placeholder={'Selecciona una o dos ocupaciones'}
                                                        onChange={(el)=>{
                                                            field.onChange(el)
                                                        }}
                                                    />
                                                )}}
                                        />
                                    </div>}

                                </div>
                                {errorMessage ? <div className={'alert alert-danger'}>{errorMessage}</div> : null}
                                <UploadImage name={'Foto de perfil**'} description={()=>{return(<small>Recomendaciones para foto <ul>
                                    <li>Capturada de los hombros para arriba</li>
                                    <li>Fondo claro de frente y cabeza completa</li>
                                    <li>Sin objetos que cubran cara o cabeza</li>
                                    <li>No mayor a 30 días de antigüedad</li>
                                </ul></small>)}} setParentImage={setProfilePic} parentImage={profilePic} setParentLoading={()=>{}} setParentStopLoading={()=>{}} />
                                <p className={'serviceLabel mt-4'}>Descripción general de sus trabajo (Puede incluir experiencia, área en la que te especializas, cómo realizas tu trabajo, etc. ) **</p>
                                <textarea
                                    rows="4"
                                    className={'form-control'}
                                    placeholder={'Descripción...'}
                                    value={description}
                                    onChange={(event)=>{
                                        setDescription(event.target.value)
                                    }}
                                />
                                <button hidden={true} ref={refSubmitButton} type={"submit"} />
                            </form>
                            <div className="form-group my-3">
                                <label >Servicios en los que te especializas</label>
                                <p style={{fontSize: 14}}>En esta sección puedes especificar, describir, y subir una fotografía de los servicios en los que especializas dentro de tu área de trabajo.</p>
                                {localServices.map((el,i)=>{
                                    return(
                                        <Service
                                            key={el._id ? el._id :i}
                                            serviceNumber={el._id ? el._id :i +1}
                                            id={el._id ? el._id : false}
                                            setParentLoading={setNewLoading}
                                            upload={upload}
                                            setParentStopLoading={setRemoveLoading}
                                        />
                                    )
                                })}
                                <button
                                    type={'button'}
                                    className={'btn btn-primary w-100'}
                                    onClick={()=>{
                                        const newArray = [...localServices];
                                        newArray.push({});
                                        setLocalServices(newArray);
                                    }}
                                >
                                    Agregar otro servicio
                                </button>
                            </div>
                            <div className={'my-5'} style={{textAlign: "right"}}>
                                <button disabled={loading.length >= 1} type="button" className="btn btn-primary" onClick={()=>{refSubmitButton?.current?.click();}} >Siguiente</button>
                            </div>
                            {errorMessage && <div className={'alert alert-danger'}>{errorMessage}</div>}


                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default SecondStep
