import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const servicesApi = createApi({
    reducerPath: 'serviceApi',
    baseQuery: fetchBaseQuery({
        baseUrl:'/api/services',
        prepareHeaders:(headers, {getState, forced})=>{
            const { search } = window.location;
            const behalf_user_id = new URLSearchParams(search).get('behalf_user_id');
            const token = getState().auth.token;
            if(token){
                headers.set('Authorization', `Bearer ${token}`);
                if(behalf_user_id){
                    console.log('mandando peticion on behalf de:: ', behalf_user_id)
                    headers.set('on_behalf',  behalf_user_id )
                }
            }
            forced = true;
            return headers

        }
    }),
    endpoints: (builder) => ({
        getServicesList: builder.query({
            query: ()=>'/',
        })
    })
})


export const { useGetServicesListQuery } = servicesApi;