import React, {useState} from 'react';
import AdminNavBar from "../../components/AdminNavBar";
import {useDeleteOccupationMutation, useGetOccupationListQuery} from "../../redux/RTK-queries/occupation";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import UpdateOccupation from "../../components/UpdateOccupation";

const Occupations = () =>{
    const { data: occupations, isFetching, isLoading, isError } = useGetOccupationListQuery();

    const [showDelete, setShowDelete] = useState(false);
    const [occupationToDelete, setOccupationToDelete] = useState(null);

    const [showUpdate, setShowUpdate] = useState(false);
    const [occupationToUpdate, setOccupationToUpdate] = useState(null);

    const [deleteOccupation] = useDeleteOccupationMutation();

    const deleteOccupationHandler = async() =>{
        console.log('entra a borrar');
        await deleteOccupation(occupationToDelete);
        setShowDelete(false)
    }

    return(
        <>
            <AdminNavBar />
            <div className={'container-fluid'}>

                <div className={'container'}>
                    <h1>Ocupaciones</h1>
                    {(isFetching || isLoading) && <p>Loading...</p>}
                    {(!isFetching && !isLoading && occupations) && <div>
                        <table className={'table'}>
                            <thead>
                                <th>Ocupación</th>
                                <th>Imagen</th>
                                <th>Fecha de creación</th>
                                <th>Acciones</th>
                            </thead>
                            <tbody>
                                {occupations?.map( occupation => (
                                        <tr>
                                            <td>{occupation.name}</td>
                                            <td>{occupation.photo ? <a href={occupation.photo}>Ver</a> : ''}</td>
                                            <td>{occupation.creation_date}</td>
                                            <td>
                                                <a
                                                    className={'btn btn-primary'}
                                                    onClick={()=>{
                                                        setOccupationToUpdate(occupation._id)
                                                        setShowUpdate(true);
                                                    }}
                                                >Editar</a>
                                                <a
                                                    className={'mt-2 btn btn-danger'}
                                                    onClick={()=>{
                                                        setOccupationToDelete(occupation._id)
                                                        setShowDelete(true)
                                                    }}
                                                >Borrar</a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
                <Modal show={showDelete} onHide={()=>setShowDelete(false)} style={{zIndex: 100000000000}} >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Está seguro que desea borrar el elemento?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <Button variant="secondary" onClick={()=>{setOccupationToDelete(null); setShowDelete(false)}}>Cancelar</Button>
                            </div>
                            <div className={'col-6'}>
                                <Button variant="primary" onClick={deleteOccupationHandler}>Borrar</Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>
                <UpdateOccupation occupation_id={occupationToUpdate} setShow={setShowUpdate} show={showUpdate} />
            </div>
        </>

    )
}

export default Occupations