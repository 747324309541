import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const occupationApi = createApi({
    reducerPath: 'occupationApi',
    baseQuery: fetchBaseQuery({
        baseUrl:'/api/occupation',
        prepareHeaders:(headers, {getState})=>{
            const token = getState().auth.token;
            if(token){
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getOccupationList: builder.query({
            query: ()=>'/list',
            providesTags: () =>['occupations']
        }),
        getOccupationById: builder.query({
            query: (occupation_id)=>`/${occupation_id}`,
            providesTags: () =>['occupation']
        }),
        deleteOccupation: builder.mutation({
            query(occupation_id){
                return{
                    url: `/${occupation_id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: () => ['occupations']
        }),
        updateOccupation: builder.mutation({
            query({occupation_id, ...occupation}){
                return{
                    url: `/${occupation_id}`,
                    method: 'PUT',
                    body: occupation
                }
            },
            invalidatesTags: () => ['occupations']
        })
    })
})


export const { useGetOccupationListQuery, useDeleteOccupationMutation, useGetOccupationByIdQuery, useUpdateOccupationMutation } = occupationApi;