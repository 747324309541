import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../assets/css/components/services.css'
import uploadImage from '../../assets/images/upload_image.png'
import Dropzone, {useDropzone} from 'react-dropzone'
import {useSelector} from "react-redux";

const Service = ({serviceNumber, setParentLoading, upload, setParentStopLoading, id}) =>{

    const [loadingImage, setLoadingImage] = useState(false);
    const { token } = useSelector(state=>state.auth);
    const [image, setImage]= useState('')

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');


    const onDrop = useCallback(async acceptedFiles => {
        //https://images-quip.herokuapp.com
        setLoadingImage(true);
        const ranNum = Math.floor(Math.random() * 1000000);
        setParentLoading(ranNum);
        const formData  = new FormData();

        formData.append('image', acceptedFiles[0])

        try{
            const responseCrud = await fetch('https://images.quip.com.mx/api/images/upload',{
                method: 'POST',
                headers:{
                    Authorization: 'Bearer '+ token
                },
                body: formData
            })

            const response = await  responseCrud.json()
            setImage(response.Location);
            setLoadingImage(false);
            setParentStopLoading(ranNum);
        }catch(e){
            setLoadingImage(false);
            setParentStopLoading(ranNum);
            console.log(e);
        }




    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const refSubmitButton = useRef(null);


    useEffect(()=>{
        if(upload){
            refSubmitButton?.current?.click();
        }
    }, [upload])

    const getService = async ()=>{
        try{
            const response = await fetch(`api/services/${id}`,{
                method: 'GET',
                headers:{
                    Authorization: 'Bearer '+ token
                }
            })

            const responseJson = await response.json();
            setName(responseJson.name);
            setDescription(responseJson.description);
            setImage(responseJson.photo);
        }catch(e){
            console.log(e);
        }
    }

    useEffect(()=>{
        if(id){
            getService();
        }
    },[id])


    const handleSubmit=async (event)=> {
        event.preventDefault();
        const params = { name, description, photo: image }
        console.log(params);
        if(!id){
            try{
                const responseCrud = await fetch('/api/services',{
                    method: 'POST',
                    headers:{
                        'content-type': 'application/json',
                        Authorization: 'Bearer '+ token
                    },
                    body: JSON.stringify(params)
                })

                const response = await  responseCrud.json()
                console.log(response);
            }catch(e){

                console.log(e);
            }
        }else{
            try{
                const responseCrud = await fetch('/api/services/'+id,{
                    method: 'PUT',
                    headers:{
                        'content-type': 'application/json',
                        Authorization: 'Bearer '+ token
                    },
                    body: JSON.stringify(params)
                })

                const response = await  responseCrud.json()
                console.log(response);
            }catch(e){

                console.log(e);
            }
        }


        console.log('Se esta subiendo el servicio, ', name);
    }

    return(
        <section className={'serviceBorder my-3 '}>
            <form onSubmit={handleSubmit}>
                <p className={'serviceTitle'}>Servicio {serviceNumber}</p>
                <p className={'serviceLabel'}>Nombre del servicio</p>
                <input
                    className={'form-control'}
                    value={name}
                    onChange={(event)=>{
                        setName(event.target.value)
                    }}
                    placeholder={'Nombre del servicio'}
                />
                <p className={'serviceLabel mt-4'}>Descripción del tipo de servicio (Aquí puede incluir limitantes y precios base)</p>
                <textarea
                    rows="4"
                    className={'form-control'}
                    placeholder={'Descripción...'}
                    value={description}
                    onChange={(event)=>{
                        setDescription(event.target.value)
                    }}
                />
                <button hidden={true} ref={refSubmitButton} type={"submit"} />
            </form>
                <p className={'serviceLabel mt-4'}>Imagen o fotografía del servicio</p>
                {(!loadingImage && !image ) && <div {...getRootProps()} className={'dashed-border p-5 bg-white text-center'}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <>
                                <img src={uploadImage} />
                                <p>Seleccione o arrastre una imagen</p>
                            </>
                    }
                </div>}
                {loadingImage && <div className={'dashed-border p-5 bg-white text-center'}>
                </div>}
                {(!loadingImage && image) && <div className={'dashed-border p-2 bg-white text-center'}>
                    <img src={image} style={{maxWidth: 150}} />
                    <p style={{cursor: 'pointer', color:'#E24302', textAlign:'center', fontSize: 15}} onClick={()=>{setImage('')}}>Eliminar imágen</p>
                </div>}
        </section>
    )

}

export default Service;
