import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const { search } = window.location;

export const login = createAsyncThunk('login', async (data)=> {
        try{
            const responseCrud = await fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            const response = await responseCrud.json();
            if (responseCrud.status.valueOf() == 200 || responseCrud.status.valueOf() == 201 || responseCrud.status.valueOf() == 304) {
                return {user: response.user, token: response.token};
            } else {
                throw Error(response.error);
            }
        } catch (e) {
            throw Error(e);
        }
    }
)

export const checkLogin = createAsyncThunk('auth/checkLogin', async (behalf)=>{
    const behalf_user_id = behalf ??  new URLSearchParams(search).get('behalf_user_id');
    const token = localStorage.getItem('user.token');
    console.log('llamado on behalf de:: ', behalf_user_id);
    if(token){
        try{
            const responseCrud = await fetch('/api/user' ,{
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    on_behalf:  (behalf_user_id ?? undefined)
                },

            });
            const response = await responseCrud.json();
            if(responseCrud.status.valueOf() == 200 || responseCrud.status.valueOf() == 201 || responseCrud.status.valueOf() == 304){
                behalf_user_id ? response.onBehalf = true : console.log('no behalf')
                return {user: response, token}
            }else{
                throw Error(response.error);
            }
        }catch(e){
            throw Error(e);
        }
    }

})


export const createUser = createAsyncThunk('auth/createUser', async(params)=>{
    try{
        const responseCrud = await fetch('/api/user',{
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const response = await responseCrud.json();
        if(responseCrud.status.valueOf() == 200 || responseCrud.status.valueOf() == 201){
            return response
        }else{
            throw Error(response.error);
        }
    }catch(e){
        throw Error(e)
    }
})

export const updateUser = createAsyncThunk('auth/updateUser', async(params)=>{
    const token = localStorage.getItem('user.token');
    const behalf_user_id = params.behalf_user_id
    delete params.behalf_user_id
    console.log('behalf_user_id from updateUser thunk', behalf_user_id)
    
    try{
        const responseCrud = await fetch('/api/user' + (behalf_user_id ? '/'+ behalf_user_id : ''),{
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`,
                on_behalf:  (behalf_user_id ?? undefined)
            },
            body: JSON.stringify(params),
        });
        const response = await responseCrud.json();
        if(responseCrud.status.valueOf() == 200 || responseCrud.status.valueOf() == 201){
            behalf_user_id ? response.onBehalf = true : console.log('no behalf')
            return response
        }else{
            throw Error(response.error);
        }
    }catch(e){
        throw Error(e)
    }
})

const initialState = { token: '', user:{}, loadingCreateUser: false, error: null }

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
       logout: ()=>{
           localStorage.removeItem('user.token');
           return initialState;
       }
    },
    extraReducers:{
        [login.pending]: (state, action)=>{
            state.loadingCreateUser= true;
            state.user = {};
            state.error = null;
        },
        [login.fulfilled]: (state, action)=>{
            localStorage.setItem('user.token', action.payload.token)
            state.loadingCreateUser= false;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.error = null;
        },
        [login.rejected]: (state,action)=>{
            console.log('entro a error', action.error.message);
            state.loadingCreateUser= false;
            state.error = action.error.message;
        },
        [createUser.pending]: (state, action)=>{
            state.loadingCreateUser= true;
            state.user = {};
            state.error = null;
        },
        [createUser.fulfilled]: (state, action)=>{
            localStorage.setItem('user.token', action.payload.token)
            state.loadingCreateUser= false;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.error = null;
        },
        [createUser.rejected]: (state,action)=>{
            console.log('entro a error', action.error.message);
            state.loadingCreateUser= false;
            state.error = action.error.message;
        },
        [updateUser.pending]: (state, action)=>{
            state.loadingUpdateUser= true;
            state.error = null;
        },
        [updateUser.fulfilled]: (state, action)=>{
            state.loadingUpdateUser= false;
            state.user = action.payload;
            state.error = null;
        },
        [updateUser.rejected]: (state,action)=>{
            state.loadingUpdateUser= false;
            state.error = action.error.message;
        },
        [checkLogin.fulfilled]: (state,action)=>{
            state.user = action.payload.user;
            state.token = action.payload.token;
        }
    }
})

export default authSlice.reducer
export const { logout } = authSlice.actions
