import React, {useEffect} from 'react';
import NavBar from "../components/NavBar";
import logo from "../assets/images/logo_quip.png";
import {FaUserAlt} from "react-icons/fa";
import {AiFillPhone, AiOutlineLock, AiOutlineMail} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkLogin, login} from "../redux/authSlice";

const Login = () => {
    const { register, handleSubmit } = useForm();

    const { token, user, error } = useSelector(state => state.auth)

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(checkLogin());
    }, [])


     useEffect(()=>{
        if(token != ''){
            navigate('/second');
        }
     }, [token])

    const onSubmit = data => {
        const {email, password} = data;
        if(email, password){
            dispatch(login(data));
        }else{
            alert("Por favor llene todos los campos");
        }
    }
    return(
        <>
            <div className="container-fluid my-0 px-0">
                <div className={'d-block d-md-none'}>
                    <NavBar  />
                </div>
                <div className={'row '}>
                    <div className={'col-md-6 h-100 background-image ps-lg-5 d-none  d-md-block '} style={{minHeight: 1000}}>
                        <div className={'text-center'}>
                            <a href={'https://quip.com.mx'}><img src={logo} alt="logo" className={'mt-md-5 '}/></a>
                        </div>
                        <div className={'mt-md-5 text-center text-white'}>
                            <h1 className={'text-white'}>Únete a Quip Solutions</h1>

                        </div>

                    </div>
                    <div className={'col-md-6 fondo-corporativo pt-3 px-5 pb-5'}>
                        <h3 className={'text-center'}>Inicia Sesión</h3>
                        <form onSubmit={handleSubmit(onSubmit)} className={'px-lg-5 mt-5'}>
                            <div className="form-group my-3">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiOutlineMail size={21} /></div>
                                    </div>
                                    <input type="email"  {...register("email")} className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="form-group my-3">

                                <label htmlFor="exampleInputPassword1">Password</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><AiOutlineLock size={21} /></div>
                                    </div>
                                    <input type="password" className="form-control"  {...register("password")}
                                           placeholder="Contraseña" />
                                </div>

                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="text-center mt-4">
                                <input type="submit" className="btn btn-primary " value={'Submit'} />
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;

