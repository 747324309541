import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const userApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl:'/api/user',
        prepareHeaders:(headers, {getState})=>{
            const token = getState().auth.token;
            if(token){
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getUserList: builder.query({
            query: ()=>'/list',
            providesTags: (result) =>['users']
        }),
        deleteUser: builder.mutation({
            query(user_id) {
                console.log('Usuario a borrar', user_id);
                return {
                    url: `/${user_id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result) => ['users']

        }),
        editSubscriptionUser: builder.mutation({
            query({user_id, validUntil}) {
                return {
                    url: `/${user_id}/subscription`,
                    method: 'PUT',
                    body: {validUntil}
                }
            },
            invalidatesTags: (result) => ['users']

        })
    })
})

/*export const pokemonApi = createApi({
    reducerPath: 'pokemonApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://pokeapi.co/api/v2/' }),
    endpoints: (builder) => ({
        getPokemonByName: builder.query({
            query: (name) => `pokemon/${name}`,
        }),
    }),
})

export const { useGetPokemonByNameQuery } = pokemonApi*/

export const { useGetUserListQuery, useDeleteUserMutation, useEditSubscriptionUserMutation } = userApi;