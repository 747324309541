import { configureStore } from "@reduxjs/toolkit";
import  authReducer  from "./authSlice";
import { occupationApi } from "./RTK-queries/occupation";
import {setupListeners} from "@reduxjs/toolkit/query";
import {servicesApi} from "./RTK-queries/services";
import {userApi} from "./RTK-queries/users";

export const store =  configureStore({
    reducer: {
        auth: authReducer,
        [occupationApi.reducerPath]: occupationApi.reducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        [userApi.reducerPath]: userApi.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(occupationApi.middleware)
            .concat(servicesApi.middleware)
            .concat(userApi.middleware)
})

setupListeners(store.dispatch);