import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {useGetOccupationByIdQuery, useUpdateOccupationMutation} from "../redux/RTK-queries/occupation";
import {useForm} from "react-hook-form";
import {FaUserAlt} from "react-icons/fa";
import UploadImage from "./UploadImage";

const UpdateOccupation = ({occupation_id, show, setShow}) =>{


    const {data: occupation, isLoading, isFetching} = useGetOccupationByIdQuery(occupation_id);

    const [name, setName] = useState('');
    const [image, setImage] =useState(null);
    const [loading, setLoading] = useState(false);

    const [updateOccupation] = useUpdateOccupationMutation();

    useEffect(()=>{
        if(occupation){
            setName(occupation.name)
            setImage(occupation.photo)
        }
    }, [occupation])


    const updateOccupationHandler = async() =>{
        await updateOccupation( {occupation_id, name, photo: image})
        setShow(false)
    }

    useEffect(()=>{
        const lookForOccupation = async () =>{
            //const newOccupation = getOccupationById(occupation_id);
            //setOccupation(newOccupation);
        }
        if(occupation_id){
            lookForOccupation().then(()=>{})
        }
    }, [])

    return(
        <Modal show={show} onHide={()=>setShow(false)} style={{zIndex: 100000000000}} >
            <Modal.Header closeButton>
                <Modal.Title>Confirmar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {(isLoading || isFetching) && <p>...loading</p>}
                {(!isLoading && !isFetching) && <>
                    <div className="form-group my-3">
                        <label htmlFor="name">Nombre</label>
                        <div className="input-group mb-2">
                            <input
                                type="text"
                                value={name}
                                className="form-control"
                                placeholder="Nombre"
                                onChange={(event)=>setName(event.target.value)}
                            />
                        </div>
                    </div>
                    <UploadImage
                        name={'Foto '}
                        setParentImage={setImage}
                        parentImage={image}
                        setParentLoading={setLoading}
                        setParentStopLoading={()=>{}}
                    />
                </>}

            </Modal.Body>

            <Modal.Footer>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <Button variant="secondary" onClick={()=>{ setShow(false)}}>Cancelar</Button>
                    </div>
                    <div className={'col-6'}>
                         <Button variant="primary" onClick={updateOccupationHandler}>Actualizar</Button>
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    )
}

export default UpdateOccupation