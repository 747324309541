import React, {useEffect} from 'react';
import NavBar from "../components/NavBar";
import {AiFillCheckCircle} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/authSlice";
import {useNavigate} from "react-router-dom";


const Success= () =>{
    const dispatch= useDispatch()
    const navigate = useNavigate();

    const { token, user, error } = useSelector(state => state.auth)


   useEffect(()=>{
       if(!token){
              navigate('/');
       }
   },[user])


    return(
        <div className={'container-fluid'}>
            <NavBar back={'/third'} />
            <div className={'mt-5 pt-5 text-center'}>
                <AiFillCheckCircle size={100} color={'#E24302'} />
                <h2>Tu perfil se ha creado con éxito</h2>
                <p>Muchas gracias por tu registro, te recordamos que tus meses gratis serán Agosto y Septiembre, una vez que la aplicación esté funcionando!</p>
                <p onClick={()=>{navigate('/second')}} className={'btn btn-secondary'}>Revisar datos</p>
                <p onClick={()=>{dispatch(logout())}} className={'btn btn-primary'}>Cerrar sesión</p>
            </div>
        </div>
    )
}

export default Success