import React from 'react';
import logo from '../assets/images/logo_quip.png'
import '../assets/css/navbar.css'
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/authSlice";

const NavBar = ({back}) =>{
    const location = useLocation();

    const dispatch = useDispatch();

    const {token, user} = useSelector(state => state.auth);

    return(
        <div className={'w-100'}>
            <nav style={{position: 'fixed', zIndex:100000 }} className="py-2 px-2 mx-0 bg-white w-100" id='mainNav'>
                <div className="container-fluid">
                    <div className={'row'}>
                        <div className={'col-2 pt-3'}>
                            {back && <Link to={back} className={'px-2 color-gray'} >{'<- Atrás'}</Link>}
                        </div>
                        <div className={'col-8 text-center'}>
                            <a href={'https://quip.com.mx'}><img src={logo} style={{width: 50}} /></a>
                        </div>
                        <div className={'col-sm-2'}>
                            {(user.type === 'ADMIN' || user.onBehalf) && <Link to={'/admin'} className={'px-2 color-gray'} >{'Dashboard'}</Link>}
                            {token && <div onClick={()=>{dispatch(logout())}}  className={'px-2 color-primary'}>Cerrar sesión</div>}
                        </div>

                    </div>

                </div>
            </nav>
            <div className="container-fluid" style={{height: 100}}></div>
        </div>

    )
}

export default NavBar;