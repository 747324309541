import React from 'react';

const Blog = () =>{
    return(
        <div>
            My blog
        </div>
    )
}

export default Blog
